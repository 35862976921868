let arrResources = [
    // { type: 'style', url: '@styles/index.scss' },
    { type: 'script', url: 'https://www.google.com/recaptcha/api.js?render=6LfMUxMqAAAAAEeYqqVHgTyG6BnAStRSBwBfptOx' }, // ключ сайта (Google reCAPTCHA)
    // { type: 'script', url: 'https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js' },
    // { type: 'script', url: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js' },
]

function lazyLoadCSSandJS(arrResources) {
    arrResources.forEach(item => {
        if (item.type === 'script') {
            let node = document.createElement('script');
            node.src = item.url;
            document.head.appendChild(node);
        }
    })
}

window.addEventListener("load", function () {
    lazyLoadCSSandJS(arrResources);
});